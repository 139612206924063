import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Icon } from "vant";
import { createPinia } from "pinia";

import { useStore } from "@/store/login";
// 2. 引入组件样式
import "vant/lib/index.css";
import "@/static/style/index.scss"
import "@/static/style/iconfont.css"
const app = createApp(App);

app.use(createPinia());
const beforeAppMount = async () => {
  app.use(createPinia());
  const store = useStore();
  await store.authToken();
};
beforeAppMount().then(() => {
  app.use(router).use(Icon).mount("#app");
});
